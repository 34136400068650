export const ROLE_GUEST = 1
export const ROLE_STAFF_PANIC = 11
export const ROLE_STAFF_OPS = 2
export const ROLE_STAFF_SALES = 3
export const ROLE_STAFF_MANAGER = 6
export const ROLE_CLIENT = 4
export const ROLE_PARTNER = 5
export const ROLE_ADMINISTRATIVE = 20
export const ROLE_ADMIN = 42

export function roles () {
    return [
        { value: ROLE_GUEST, label: 'Invité', color: 'gray-600' },
        { value: ROLE_STAFF_PANIC, label: 'Kray Staff Panic Mode', color: 'red-400' },
        { value: ROLE_STAFF_OPS, label: 'Kray Staff OPS', color: 'blue-600' },
        { value: ROLE_STAFF_SALES, label: 'Kray Staff SALES', color: 'blue-600' },
        { value: ROLE_STAFF_MANAGER, label: 'Kray Staff MANAGER', color: 'blue-600' },
        { value: ROLE_CLIENT, label: 'Client', color: 'green-600' },
        { value: ROLE_PARTNER, label: 'Partenaire', color: 'orange-600' },
        { value: ROLE_ADMINISTRATIVE, label: 'Administration', color: 'red-600' },
        { value: ROLE_ADMIN, label: 'Admin', color: 'red-600' }
    ]
}
